<template>
  <div>
    <SearchResult
      :activeTab="activeTab"
      :tabs="tabs"
      ref="topsearch"
      :result="param"
      :change="change"
    >
      <template slot="tab-head-books">
        książki
      </template>
      <template slot="tab-panel-books">
        <div v-if="isResultEmpty === true">
          <SearchResultEmpty />
        </div>
        <div class="books-list-masonry" v-else>
          <div
            v-for="(book, key) in searchingRequestResult"
            :key="key"
          >
            <SearchResultListBook :res="book" />
          </div>
        </div>
          <infinite-loading @infinite="callGetBooks">
            <div slot="spinner" class="d-flex align-items-center justify-content-center w-100">
              <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
            </div>
            <div slot="no-results"><div class="pt-5 pb-5">To już wszystko...</div></div>
            <!-- <div slot="no-results"><SearchResultEmpty /></div> -->
           <!-- <div slot="no-more"><SearchResultEmpty /></div> -->
        </infinite-loading>
      </template>

      <template slot="tab-head-people">
        osoby
      </template>
      <template slot="tab-panel-people">
        <div v-if="searchingRequestResult.length === 0 && isResultEmpty === false">
          <SearchingPlaceholder />
        </div>
        <div v-else-if="isResultEmpty === true">
          <SearchResultEmpty />
        </div>
          <div
            class="books-list-masonry" v-else
          >
            <div
              v-for="(book, key) in searchingRequestResult"
              :key="key"
            >
              <SearchResultListPeople :res="book" @update="updatePeopleList"/>
            </div>
          </div>
      </template>

      <template slot="tab-head-bookstore">
        księgarnie
      </template>
      <template slot="tab-panel-bookstore">
        <div v-if="isResultEmpty === true">
          <SearchResultEmpty />
        </div>
        <div class="books-list-masonry" v-else>
          <div
            v-for="(result, $index) in searchingRequestResult"
            :key="$index">
            <SearchResultListBookstore :res="result" />
          </div>
        </div>
          <infinite-loading @infinite="callGetBookstore">
            <div slot="spinner" class="d-flex align-items-center justify-content-center w-100">
              <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
            </div>
            <div slot="no-results"><div class="pt-5 pb-5">To już wszystko...</div></div>
        </infinite-loading>
      </template>

      <template slot="tab-head-author">
        autorzy
      </template>
      <template slot="tab-panel-author">
       <div v-if="isResultEmpty === true">
          <SearchResultEmpty />
        </div>
        <div class="books-list-masonry">
          <div
            v-for="(result, index) in searchingRequestResult"
            :key="index"
          >
            <SearchResultListAuthor :res="result" />
          </div>
        </div>
          <infinite-loading @infinite="callGetAuthor">
            <div slot="spinner" class="d-flex align-items-center justify-content-center w-100">
              <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
            </div>
            <div slot="no-results"><div class="pt-5 pb-5">To już wszystko...</div></div>
            <!-- <div slot="no-results"><SearchResultEmpty /></div> -->
            <!-- <div slot="no-more"><SearchResultEmpty /></div> -->
        </infinite-loading>
      </template>

        <template slot="tab-head-publishing">
        wydawnictwa
      </template>
      <template slot="tab-panel-publishing">
        <div v-if="isResultEmpty === true">
          <SearchResultEmpty />
        </div>
        <div class="books-list-masonry">
          <div
            v-for="(result, $index) in searchingRequestResult"
            :key="$index"
          >
            <SearchResultListPublishing :res="result" />
          </div>
        </div>
          <infinite-loading @infinite="callGetPublishing">
            <div slot="spinner" class="d-flex align-items-center justify-content-center w-100">
              <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
            </div>
            <div slot="no-results"><div class="pt-5 pb-5">To już wszystko...</div></div>
            <!-- <div slot="no-results"><SearchResultEmpty /></div> -->
            <!-- <div slot="no-more"><SearchResultEmpty /></div> -->
        </infinite-loading>
      </template>
    </SearchResult>
  </div>
</template>
<script>
import SearchResult from '../components/SearchResult'
import SearchingPlaceholder from '../components/SearchingPlaceholder'
import SearchResultListAuthor from '../components/SearchResultListAuthor'
import SearchResultListBook from '../components/SearchResultListBook'
import SearchResultListPeople from '../components/SearchResultListPeople'
import SearchResultListPublishing from '../components/SearchResultListPublishing'
import SearchResultListBookstore from '../components/SearchResultListBookstore'
import SearchResultEmpty from '../components/SearchResultEmpty'

export default {
  name: 'SearchResultTemp',
  components: {
    SearchResult,
    SearchingPlaceholder,
    SearchResultListAuthor,
    SearchResultListBook,
    SearchResultListPeople,
    SearchResultListPublishing,
    SearchResultListBookstore,
    SearchResultEmpty
  },
  props: {
    param: String
  },
  watch: {
    param: function (val) {
      this.value = val
    }
  },
  computed: {
    searchingRequestResult: {
      get () {
        return this.$store.state.searchingRequestResult
      }
    },
    isResultEmpty: {
      get () {
        return this.$store.state.isResultEmpty
      }
    }
  },
  data () {
    return {
      activeTab: 'books',
      value: '',
      tabs: ['books', 'people', 'author', 'publishing', 'bookstore'],
      change: 0
    }
  },
  methods: {
    updatePeopleList () {
      this.change = this.change + 1
    },
    inputData (value) {
      this.searchingValue = value
    },
    callGetBooks (e) {
      this.$refs.topsearch.requestBooks(e)
    },
    callGetAuthor (e) {
      this.$refs.topsearch.requestAuthor(e)
    },
    callGetPublishing (e) {
      this.$refs.topsearch.requestPublishing(e)
    },
    callGetBookstore (e) {
      this.$refs.topsearch.requestBookstore(e)
    }
  }
}
</script>
<style lang="scss" scoped>
// .search-wrapper {
//   margin-top: 115px;
//   margin-bottom: 75px;
// }
.books-list-masonry {
  padding-bottom: 0px;
}
</style>
