<template>
  <div>
    <div class="top-menu-visible top-menu-dark">
      <div class="app-title sub-title">
        <span class="app-title-text">Bookme</span>
        <div class="container mt-3">
          <div class="input-with-icon mb-2">
            <input
              type="text"
              class="form-input w-100"
              placeholder="Wpisz tytuł, autora, frazę lub ISBN"
              v-model="value"
              ref='inputVal'
              autocorrect="off"
              autocomplete="off"
              @keydown.enter="newSearchHandler(value)"
            />
            <button class="input-side-icon" @click="newSearchHandler(value)"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div class="sliding-nav" v-if="searchingValue.length >= 1">
          <ul class="nav nav-tabs">
            <li v-for="tab in tabs" :key="tab">
              <a
                class="tab-heads"
                :class="{ active: active === tab, 'tab-small': small}"
                @click="switchTab(tab)"
                >
                  <slot :name="tabHeadSlotName(tab)">
                    {{tab}}
                  </slot>
                </a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
     <Loader v-if="isLoading == true" />
    <div class="app-content-box bg-gray">
      <div>
        <div class="container result-wrapper">
          <div class="tab-content">
            <slot :name="tabPanelSlotName"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '../components/Loader'
// import EventBus from '../eventBus'
export default {
  name: 'SearchResult',
  props: {
    tabs: Array,
    activeTab: String,
    small: Boolean,
    result: String,
    change: Number
  },
  components: {
    Loader
  },
  created () {
    this.value = this.result
    this.searchingValue = this.value
    this.searchHandler(this.value)
  },
  watch: {
    result: function (val) {
      // this.value = val
      // this.searchHandler(val)
    },
    change: function (value) {
      this.sendRequest(this.active)
    }
  },
  data () {
    return {
      isLoading: true,
      searchingValue: '',
      active: this.activeTab,
      value: '',
      errorMessage: '',
      getAllPage: 1,
      getAllList: []
    }
  },
  computed: {
    my_id () {
      return this.$store.state.userData.userId
    },
    tabPanelSlotName () {
      return 'tab-panel-' + this.active
    },
    searchingParam: {
      get () {
        return this.$store.state.searchingParam
      },
      set (value) {
        this.$store.commit('updateSearchingParam', value)
      }
    },
    searchingRequestResult: {
      get () {
        return this.$store.state.searchingRequestResult
      },
      set (value) {
        this.$store.commit('updateSearchingResult', value)
      }
    },
    isResultEmpty: {
      get () {
        return this.$store.state.isResultEmpty
      },
      set (value) {
        this.$store.commit('updateIsResultEmpty', value)
      }
    }
  },
  methods: {
    tabHeadSlotName (tabName) {
      return 'tab-head-' + tabName
    },
    switchTab (tabName) {
      this.errorMessage = ''
      this.active = tabName
      this.sendRequest(this.active)
    },
    newSearchHandler (value) {
      if (value.length === 0) {
        this.searchingValue = ''
        this.$router.push({ name: 'SearchTemplate' })
      // }
      // else if (value.length > 0 && value.length < 3) {
      // alert('Proszę podać przynajmniej 3 znaki')
      } else {
        this.searchingValue = this.$refs.inputVal.value
        if (this.searchingValue !== value) {
          setTimeout(() => {
            this.searchingValue = this.$refs.inputVal.value
            this.$router.push({ name: 'SearchResultTemp', params: { param: this.searchingValue } })
          }, 1)
        }
        this.sendRequest(this.active)
      }
    },
    searchHandler (value) {
      if (value.length === 0) {
        this.searchingValue = ''
        this.$router.push({ name: 'SearchTemplate' })
      // }
      // else if (value.length > 0 && value.length < 3) {
      // alert('Proszę podać przynajmniej 3 znaki')
      } else {
        if (this.searchingValue !== value) {
          this.$router.push({
            name: 'SearchResultTemp',
            params: { param: value }
          })
          this.searchingValue = value
        }
        this.sendRequest(this.active)
      }
    },
    sendRequest (e) {
      this.searchingRequestResult = []
      this.isLoading = true
      this.getAllPage = 1
      switch (e) {
        case 'all':
          this.requestAll()
          window.scrollTo(0, 0)
          break
        case 'books':
          this.requestBooks()
          window.scrollTo(0, 0)
          break
        case 'people':
          this.requestPeople()
          window.scrollTo(0, 0)
          break
        case 'bookstore':
          this.requestBookstore()
          window.scrollTo(0, 0)
          break
        case 'author':
          this.requestAuthor()
          window.scrollTo(0, 0)
          break
        case 'publishing':
          this.requestPublishing()
          window.scrollTo(0, 0)
          break
      }
    },
    requestAll ($state) {
      const getData = `action=all&question=${this.searchingValue}&page=${this.getAllPage}`
      this.$https('/search', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(res => {
        this.isLoading = false
        this.isResultEmpty = false
        if (res.data.response.count === '0') {
          this.isResultEmpty = true
        }
        if (res.data.response.items.length) {
          this.getAllPage += 1
          this.searchingRequestResult.push(...res.data.response.items)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
        .catch(error => {
          console.log('', error)
        })
    },
    requestBooks ($s) {
      this.isResultEmpty = false
      const getData = `action=title&question=${this.searchingValue}&page=${this.getAllPage}`
      this.$https('/search', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(({ data }) => {
        this.isResultEmpty = false
        if (this.searchingValue.length >= 10 && !isNaN(this.searchingValue)) {
          this.value = ''
        }
        if (data.response.count === '0' || data.response.count === 0) {
          this.isResultEmpty = true
        }
        this.isLoading = false
        if (data.response.items.length) {
          this.getAllPage = this.getAllPage + 1
          this.searchingRequestResult.push(...data.response.items)
          $s.loaded()
        } else {
          $s.complete()
        }
      })
        .catch(error => {
          console.log('', error)
        })
    },
    requestPeople () {
      this.isResultEmpty = false
      this.$https('/search/user?user=' + this.searchingValue, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {

        }
      })
        .then(res => {
          this.isLoading = true
          const id = this.my_id.toString()
          res.data.response.forEach(e => {
            if (e.id !== id) {
              this.searchingRequestResult.push(e)
            }
          })
          if (this.searchingRequestResult.length === 0) {
            this.isResultEmpty = true
          }
          this.isLoading = false
        })
        .catch(error => {
          console.log('', error)
        })
    },
    requestBookstore ($state) {
      const getData = `action=bookshops&question=${this.searchingValue}&page=${this.getAllPage}`
      this.$https('/search', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(res => {
        this.isLoading = false
        this.isResultEmpty = false
        if (res.data.response.count === '0') {
          this.isResultEmpty = true
        }
        if (res.data.response.items.length) {
          this.getAllPage += 1
          this.searchingRequestResult.push(...res.data.response.items)
          $state.loaded()
          this.isLoading = false
        } else {
          $state.complete()
        }
      })
        .catch(error => {
          console.log('', error)
        })
    },
    requestAuthor ($state) {
      const getData = `action=author&question=${this.searchingValue}&page=${this.getAllPage}`
      this.$https('/search', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(res => {
        this.isLoading = false
        this.isResultEmpty = false
        if (res.data.response.count === '0') {
          this.isResultEmpty = true
        }
        if (res.data.response.items.length) {
          this.getAllPage += 1
          this.searchingRequestResult.push(...res.data.response.items)
          $state.loaded()
          this.isLoading = false
        } else {
          $state.complete()
        }
      })
        .catch(error => {
          console.log('', error)
        })
    },
    requestPublishing ($state) {
      const getData = `action=publisher&question=${this.searchingValue}&page=${this.getAllPage}`
      this.$https('/search', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(res => {
        this.isLoading = false
        this.isResultEmpty = false
        if (!res.data.response.length && this.getAllPage === 1) {
          this.isResultEmpty = true
        }
        if (res.data.response.length) {
          this.getAllPage += 1
          res.data.response.forEach(e => {
            if (e.items.length) {
              this.searchingRequestResult.push(e)
            }
          })
          if (!this.searchingRequestResult.length) {
            this.requestPublishing()
          }
          $state.loaded()
          this.isLoading = false
        } else {
          $state.complete()
        }
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .result-wrapper {
    margin-top: 120px;
    margin-bottom: 30px;
  }
  .wrapper {
    margin-top: -30px;
  }
  .app-content-box {
    min-height: 100vh;
  }
</style>
